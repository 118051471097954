import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import api from '../../components/api/api';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import image from '../../assets/FOTO FUNDO LOGIN.png'
import './Carousel.css';

const Carousel = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("/eventos");
        const events = response.data.listeventos.slice(0, 3); // Get the first 3 events

        // Para cada evento, buscamos a URL da imagem e associamos ao evento
        const updatedEvents = await Promise.all(events.map(async (event) => {
          const imageUrlResponse = await api.get(`/minioRoutas/generate-presigned-url-get/${event.id}`);
          const imageUrl = imageUrlResponse.data.url;
          return { ...event, image: imageUrl }; // adiciona a URL da imagem ao evento
        }));

        // Recupera os eventos ocultados do localStorage
        const hiddenEvents = JSON.parse(localStorage.getItem('hiddenEvents')) || [];

        // Filtra os eventos que não estão na lista de ocultados
        const visibleEvents = updatedEvents.filter(event => !hiddenEvents.includes(event.id));

        setData(visibleEvents);
      } catch (error) {
        console.error("Erro ao buscar eventos:", error);
      }
    };

    fetchData();
  }, []);

  const handleDescription = (id) => {
    localStorage.setItem("idevento", id);
    navigate("/descricao");
  }

  return (
    <div className="carousel-container my-8 mx-auto max-w-6xl rounded-2xl shadow-2xl items-center hidden-mobile">
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={30}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        className="custom-swiper"
      >
        {data.map((event) => (
          <SwiperSlide key={event.id}>
            <div className="carousel-slide flex bg-white shadow-lg rounded-lg overflow-hidden">
              <div className="w-full h-full">
                <img
                  src={event.image || image}
                  alt={`Slide ${event.id}`}
                  className="object-cover w-full h-full"
                />
              </div>
              <div className="hidden md:flex flex-col justify-center p-8 w-full md:w-1/3 h-full rounded-r-2xl">
                <div className="flex items-center mb-4">
                  <div className="bg-black p-3 rounded-full flex items-center justify-center">
                    <FontAwesomeIcon
                      icon={faCalendarCheck}
                      color="white"
                      size="lg"
                    />
                  </div>
                  <div className="ml-3">
                    {new Date(event.data_inicio).toLocaleDateString()} |{" "}
                    {new Date(event.data_inicio).toLocaleTimeString()}
                    <h5 className="">{event.local}</h5>
                  </div>
                </div>
                <h2 className="text-xl md:text-3xl mt-4 font-bold">{event.title}</h2>
                <p className="text-sm md:text-xl mt-2">{event.description_shorts}</p>
                <button
                  onClick={() => handleDescription(event.id)}
                  className="bg-[#134A6D] text-white px-3 py-2 md:px-6 md:py-3 rounded-full mt-4 text-center"
                >
                  FAZER INSCRIÇÃO
                </button>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Carousel;
