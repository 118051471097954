import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/LogoIconBranca.png';
import ImagemFundo from '../../assets/FOTO-FUNDO-PRINCIPAL.png';
import { IoMdArrowRoundBack } from "react-icons/io";
import api from '../../components/api/api';

function Sede() {
    const navigate = useNavigate();
    const [cidadeLocal, setCidadeLocal] = useState('');
    const [estadoLocal, setEstadoLocal] = useState('');

    useEffect(() => {
        // Buscar o endereço pelo ID do localStorage
        const enderecoId = localStorage.getItem('enderecoId');

        if (!enderecoId) {
            alert("ID do endereço não encontrado. Por favor, verifique e tente novamente.");
            return;
        }

        const fetchEndereco = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    alert("Token de autenticação não encontrado. Por favor, faça login novamente.");
                    navigate('/login');
                    return;
                }

                // Requisição para buscar o endereço usando o ID armazenado
                const response = await api.get(`/endereco/${enderecoId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                const { localidade, uf } = response.data; // Extraímos cidade (localidade) e UF
                setCidadeLocal(localidade);  // Preenche a cidade
                setEstadoLocal(uf);  // Preenche o estado
                localStorage.setItem('cidadeLocal', localidade);  // Armazena no localStorage
                localStorage.setItem('estadoLocal', uf);  // Armazena no localStorage

            } catch (error) {
                console.error("Erro ao buscar o endereço:", error);
                alert("Erro ao buscar o endereço. Por favor, tente novamente.");
            }
        };

        fetchEndereco();
    }, [navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!cidadeLocal || !estadoLocal) {
            alert("Por favor, preencha todos os campos antes de continuar.");
            return;
        }

        const enderecoId = localStorage.getItem('enderecoId');

        if (!enderecoId) {
            alert("ID do endereço não encontrado. Por favor, verifique e tente novamente.");
            return;
        }

        const token = localStorage.getItem('token');

        if (!token) {
            alert("Token de autenticação não encontrado. Por favor, faça login novamente.");
            navigate('/login');
            return;
        }

        const sede = {
            nome: cidadeLocal,  // Enviando cidade como nome da sede
            endereco_id: enderecoId,
        };

        try {
            const response = await api.post('/sedes', sede, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status === 201 || response.status === 200) {
                const sedeId = response.data.id;
                localStorage.setItem('sedeId', sedeId);
                navigate('/adicionar-sede');
            } else {
                console.error('Erro na resposta da API:', response);
                alert("Erro ao enviar sede! Status: " + response.status);
            }
        } catch (error) {
            console.error('Erro ao enviar sede:', error.response ? error.response.data : error.message);
            alert("Erro ao enviar sede! " + (error.response?.data?.message || error.message));
        }
    };

    const handleFieldChange = (setter, key) => (e) => {
        setter(e.target.value);
        localStorage.setItem(key, e.target.value); // Salva no localStorage ao alterar o campo
    };

    return (
        <div className="flex flex-col md:flex-row h-screen w-full">
            <div className="flex-1 md:w-1/3 bg-white flex flex-col justify-center p-4 md:p-6 lg:p-8">
                <div className="flex items-center mb-11 ml-8">
                    <IoMdArrowRoundBack className="text-2xl text-blue-500 size-7" />
                    <button
                        onClick={() => navigate(-1)}
                        className="text-xl text-blue-500 hover:text-blue-700 font-bold"
                    >
                        VOLTAR
                    </button>
                </div>
                <div className="flex flex-col items-center">
                    <h2 className="text-2xl md:text-3xl font-bold mb-2 md:mb-7">CRIAR SEDE DO EVENTO</h2>
                    <form onSubmit={handleSubmit} className="w-full max-w-xs space-y-4">
                        <div>
                            <label htmlFor="cidadeLocal" className="block text-sm font-bold mb-1 ml-4">CIDADE DA SEDE</label>
                            <input
                                type="text"
                                id="cidadeLocal"
                                className="w-full border rounded-full px-3 py-2"
                                value={cidadeLocal}
                                onChange={handleFieldChange(setCidadeLocal, 'cidadeLocal')}
                            />
                        </div>
                        <div>
                            <label htmlFor="estadoLocal" className="block text-sm font-bold mb-1 ml-4">ESTADO DA SEDE</label>
                            <input
                                type="text"
                                id="estadoLocal"
                                className="w-full border rounded-full px-3 py-2"
                                value={estadoLocal}
                                onChange={handleFieldChange(setEstadoLocal, 'estadoLocal')}
                            />
                        </div>
                        <div className='flex justify-center items-center'>
                            <button type="submit" className="w-40 h-10 bg-blue-500 text-white font-bold rounded-full mt-4 justify-center flex items-center">PRÓXIMO</button>
                        </div>
                    </form>
                </div>
            </div>
            <div className="hidden md:flex md:w-2/3 relative">
                <div className="w-full h-full flex items-center justify-center relative bg-cover bg-center" style={{ backgroundImage: `url(${ImagemFundo})` }}>
                    <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                        <img src={Logo} alt="Kaio Marcio Logo" className="max-w-full h-auto p-4 md:p-6" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Sede;
