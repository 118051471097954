import React from 'react';
import api from '../../components/api/api';
import { useParams } from 'react-router-dom';
import QRCode from 'qrcode.react';
import './Descricao.css';  // Importa o arquivo de estilo separado

const Descricao = () => {
  const { id } = useParams();  // Captura o parâmetro "id" da URL
  const [data, setData] = React.useState(null);

  // Requisição para pegar os dados da API
  React.useEffect(() => {
    const token = localStorage.getItem("token");
    // Chamada para a API
    api.get(`/ingressos/myIngresso/${id}`, {
        headers: {
            authorization: `Bearer ${token}`,
        },
    })
    .then(response => {
      setData(response.data); // Supondo que `response.data` contém os dados esperados
    })
    .catch(error => {
      console.error('Erro ao buscar os dados:', error);
    });
  }, [id]);

  // Função para gerar o conteúdo do QR code com o id como parâmetro
  const generateQRCodeData = () => {
    if (data && data.ingresso && data.ingresso.evento) {
      return `http://localhost:3000/gastar-ingresso/${id}`;
    }
    return '';
  };

  return (
    <div className="descricao-container">
      <h1 className="titulo-principal">Descrição do Ingresso e Evento</h1>
      {data ? (
        <div className="detalhes-container">
          <h2>Evento: {data.ingresso?.evento?.title || 'Título do evento não disponível'}</h2>
          <p><strong>Data Início:</strong> {data.ingresso ? new Date(data.ingresso.evento.data_inicio).toLocaleString() : 'Data não disponível'}</p>
          <p><strong>Data Fim:</strong> {data.ingresso ? new Date(data.ingresso.evento.data_fim).toLocaleString() : 'Data não disponível'}</p>
          <p><strong>Status:</strong> {data.ingresso?.evento?.status || 'Status não disponível'}</p>

          <h3>Ingresso: {data.ingresso?.titulo || 'Título do ingresso não disponível'}</h3>
          <p><strong>Valor:</strong> {data.ingresso ? `R$${data.ingresso.valor}` : 'Valor não disponível'}</p>
          <h4>Informações do Ingresso </h4>
          <p><strong>ID da Transação:</strong> {data.ingressoVendido?.transactionId || 'ID da transação não disponível'}</p>
          <p><strong>Status do Ingresso:</strong> {data.ingressoVendido?.status || 'Status não disponível'}</p>
          <p><strong>Data da Venda:</strong> {data.ingressoVendido ? new Date(data.ingressoVendido.createdAt).toLocaleString() : 'Data não disponível'}</p>

          <h4>Informações do Usuário</h4>
          <p><strong>Nome:</strong> {data.user?.name || 'Nome não disponível'}</p>
          <p><strong>Email:</strong> {data.user?.email || 'Email não disponível'}</p>
          <p><strong>CPF:</strong> {data.user?.cpf || 'CPF não disponível'}</p>
          <p><strong>Telefone:</strong> {data.user?.phone || 'Telefone não disponível'}</p>

          <h4>QR Code</h4>
          <div className="qr-code-container">
            <QRCode
              value={generateQRCodeData()}  // Gera o QR Code com os dados do ingresso/evento e o ID
              size={256}
            />
          </div>

          <p><strong>ID da Transação:</strong> {data.transactionId || 'ID da transação não disponível'}</p>
        </div>
      ) : (
        <p className="loading-text">Carregando...</p>
      )}
    </div>
  );
};

export default Descricao;
