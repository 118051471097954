import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/header/index';
import Footer from '../../components/footer-main';
import ImageFundo from '../../assets/FOTO-FUNDO-PRINCIPAL.png';
import image from '../../assets/FOTO FUNDO LOGIN.png'
import logo from '../../assets/LogoIconBranca.png';
import Imagemcatalogo from '../../assets/FOTO FUNDO PRODUTOS.png';
import produto1 from '../../assets/FOTOS PRODUTOS MAIÔ.png';
import produto2 from '../../assets/FOTOS PRODUTOS ÓCULOS.png';
import produto3 from '../../assets/FOTOS PRODUTOS TOUCA.png';
import Carousel from '../../components/carrosel/index';
import api from '../../components/api/api';
import './style.css';

function Main() {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [showHeader, setShowHeader] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get("/eventos");
                const events = response.data.listeventos;

                const updatedEvents = await Promise.all(events.map(async (event) => {
                    const imageUrlResponse = await api.get(`/minioRoutas/generate-presigned-url-get/${event.id}`);
                    const imageUrl = imageUrlResponse.data.url;
                    return { ...event, image: imageUrl };
                }));

                // Recupera os eventos ocultados do localStorage
                const hiddenEvents = JSON.parse(localStorage.getItem('hiddenEvents')) || [];

                // Filtra os eventos que não estão na lista de ocultados
                const visibleEvents = updatedEvents.filter(event => !hiddenEvents.includes(event.id));

                setData(visibleEvents);
            } catch (error) {
                console.error("Erro ao buscar eventos:", error);
            }
        };

        fetchData();
    }, []);



    const handleDescription = (id) => {
        localStorage.setItem("idevento", id);
        navigate("/descricao");
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > window.innerHeight) {
                setShowHeader(false);
            } else {
                setShowHeader(true);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div>
            {showHeader && <Header />}

            <section
                className="relative h-screen bg-cover bg-center"
                style={{ backgroundImage: `url(${ImageFundo})` }}
            >
                <div className="absolute inset-0 bg-black opacity-50"></div>
                <div className="container mx-auto h-full flex flex-col md:flex-row justify-between items-center relative z-10 text-white px-4 md:px-0">
                    <div className="flex-1 flex flex-col justify-center items-center text-center md:text-left">
                        <img className="h-50 md:h-82" src={logo} alt="Logo" />
                        <p className="max-w-xl md:mb-32">
                        Participe de eventos incríveis e mergulhe no universo da natação. Nossa plataforma oferece uma variedade de eventos, incluindo workshops, clínicas, competições e encontros exclusivos, desenhados para nadadores de todos os níveis e idades.
                        </p>
                    </div>
                    <div className="flex-1 hidden md:block">
                        {/* Div de divisão de coluna */}
                    </div>
                </div>
            </section>

            <div className="bg-white flex flex-col items-center justify-center h-auto py-16">
                <div className="wave-container absolute bottom-0 left-0 w-full overflow-hidden leading-[0] -mb-12 z-20">
                    <svg viewBox="0 0 1440 320">
                        <path fill="#fff" fillOpacity="1" d="M0,224L48,213.3C96,203,192,181,288,176C384,171,480,181,576,197.3C672,213,768,235,864,245.3C960,256,1056,256,1152,240C1248,224,1344,192,1392,176L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
                    </svg>
                </div>
                <div className="container mx-auto mt-16">
                    <Carousel />
                </div>
            </div>

            <div className="events-section pt-4 pb-20">
                <h1 className="text-center text-4xl font-bold mb-8">Eventos</h1>
                <div className="flex justify-center">
                    <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 w-full px-4 card-grid">
                        {data.map((event) => (
                            <div key={event.id} className="bg-gray-100 rounded-lg overflow-hidden shadow-xl w-full mx-auto event-card " style={{ padding: '20px' }}>
                                <img
                                    src={event.image || image}
                                    alt={`Evento ${event.id}`}
                                    className="w-full h-48 object-cover rounded-t-lg"
                                />
                                <div className="p-4">
                                    <div className="flex justify-between items-center mb-2">
                                        <h3 className="font-semibold text-xl mb-2">{event.title}</h3>
                                        <button onClick={() => handleDescription(event.id)} className="px-2 py-1 mb-2 bg-[#134A6D] text-white font-bold rounded-md text-center">
                                            SAIBA MAIS
                                        </button>
                                    </div>
                                    <span className="font-medium text-sm">{event.local}</span>
                                    <p className="text-gray-600 mb-4">{event.description_shorts}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="relative-section relative bg-black w-full h-auto">
                <div className="wave-container absolute w-full overflow-hidden leading-[0] z-20" style={{ top: '-70px' }}>
                    <svg viewBox="0 0 1440 200" className="w-full transform rotate-180">
                        <path fill="#fff" fillOpacity="1" d="M0,112L48,106.7C96,101,192,90,288,88C384,85,480,90,576,98.7C672,107,768,117,864,122.7C960,128,1056,128,1152,120C1248,112,1344,96,1392,88L1440,80L1440,160L1392,160C1344,160,1248,160,1152,160C1056,160,960,160,864,160C768,160,672,160,576,160C480,160,384,160,288,160C192,160,96,160,48,160L0,160Z"></path>
                    </svg>
                </div>
                <div className="catalog-image-container relative bg-black h-auto">
                    <img src={Imagemcatalogo} alt="Imagem-fundo-catalogo" className="w-full h-full object-cover" />
                    <div className="absolute inset-0 flex flex-col items-center justify-center px-4">
                        <h1 className="text-white text-4xl md:text-7xl font-bold mt-20">NOSSOS PRODUTOS</h1>
                        <div className="products-container flex flex-col md:flex-row justify-center items-center mt-12 space-y-8 md:space-y-0 md:space-x-4">
                            <div className="product-item w-full md:w-1/4 p-3">
                                <img src={produto1} alt="Maiô de competição" className="rounded-lg w-full" />
                                <h3 className="text-white text-xl font-bold mt-4 text-center">MAIÔ DE COMPETIÇÃO</h3>
                                <p className="text-white text-center">Garantia contra defeitos de fabricação, sendo de até 3 meses após a compra com apresentação de nota fiscal.</p>
                            </div>
                            <div className="product-item w-full md:w-1/4 p-3">
                                <img src={produto2} alt="Óculos Storm" className="rounded-lg w-full" />
                                <h3 className="text-white text-xl font-bold mt-4 text-center">ÓCULOS STORM</h3>
                                <p className="text-white text-center">Pode ser utilizado em competições e treinamentos. Desenho hidrodinâmico, lentes que não embaçam e que proporcionam mais conforto. Visão lateral ampliada. Garantia contra defeitos de fabricação, sendo de até 3 meses após a compra com apresentação de nota fiscal.</p>
                            </div>
                            <div className="product-item w-full md:w-1/4 p-3">
                                <img src={produto3} alt="Touca Adulto" className="rounded-lg w-full" />
                                <h3 className="text-white text-xl font-bold mt-4 text-center">TOUCA ADULTO</h3>
                                <p className="text-white text-center">Garantia contra defeitos de fabricação, sendo de até 3 meses após a compra com apresentação de nota fiscal.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="access-our-store bg-black w-full h-full flex flex-col items-center justify-center pt-5 pb-20">
                <h1 className="text-white text-4xl md:text-5xl font-bold mb-8 mt-10">ACESSE NOSSA LOJA</h1>
                <p className="text-white text-center max-w-xl mb-8 px-4">
                Equipamentos de alta performance fazem toda a diferença no seu desempenho na piscina. Na Loja Oficial de Kaio Marcio, você encontra tudo o que precisa para elevar seu nível, com a qualidade e confiança de um campeão mundial!

Explore nossa linha exclusiva de óculos de natação, toucas, roupas esportivas e acessórios desenvolvidos especialmente para nadadores de todos os níveis. Cada item é escolhido com cuidado para proporcionar conforto, durabilidade e desempenho, ajudando você a alcançar seus objetivos dentro d'água.

Acesse agora e descubra os produtos favoritos de Kaio Marcio. Nade como um campeão!
                </p>
                <a href="https://www.kaiomarcio.com.br" target="_blank" rel="noreferrer" className="bg-blue-500 text-white font-bold py-2 px-2 rounded-lg">
                    WWW.KAIOMARCIO.COM.BR
                </a>
            </div>

            <Footer />
        </div>
    );
}

export default Main;
